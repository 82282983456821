import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import './RequestPartsPopup.css';

const RequestPartsPopup = ({ isOpen, onClose, onConfirm }) => {
  const [parts, setParts] = useState([]);
  const [showAddPart, setShowAddPart] = useState(false);
  const [newPart, setNewPart] = useState({ name: '', quantity: '' });
  const [inventory, setInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showInventoryList, setShowInventoryList] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Fetch inventory when component mounts
  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await api.get('/items/');
        setInventory(response.data);
        setFilteredInventory(response.data);
        console.log("Inventory:")
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching inventory:', error);
      }
    };
    fetchInventory();
  }, []);

  // Filter inventory based on search term
  const handleSearch = (value) => {
    setSearchTerm(value);
    setShowInventoryList(true);
    const filtered = inventory.filter(item => 
      item.item_name.toLowerCase().includes(value.toLowerCase()) ||
      item.part_id.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredInventory(filtered);
  };

  const handleSelectPart = (item) => {
    setNewPart({ 
      id: item.id,
      name: item.item_name,
      code: item.part_id,
      quantity: ''
    });
    setSearchTerm(item.item_name);
    setShowInventoryList(false);
  };

  const handleAddPart = () => {
    if (newPart.name && newPart.quantity) {
      setParts([...parts, newPart]);
      setNewPart({ name: '', quantity: '' });
      setSearchTerm('');
      setShowAddPart(false);
    }
  };

  const handleRemovePart = (index) => {
    const newParts = parts.filter((_, i) => i !== index);
    setParts(newParts);
  };

  const handleConfirmClick = () => {
    setShowConfirmation(true);
  };

  const handleFinalConfirm = () => {
    onConfirm(parts);
    setShowConfirmation(false);
  };

  if (!isOpen) return null;

  return (
    <div className="request-parts-overlay">
      <div className="request-parts-modal">
        <h2>Request Parts</h2>
        <p>Your are about to send this job to Parts, please write the required parts to finish this job.</p>
        
        <div className="parts-list">
          <div className="parts-header">
            <span>Part</span>
            <span>Quantity</span>
            <span></span>
          </div>
          {parts.map((part, index) => (
            <div key={index} className="part-item">
              <span>{part.name}</span>
              <span>{part.quantity}</span>
              <button 
                className="remove-part-btn"
                onClick={() => handleRemovePart(index)}
              >
                ×
              </button>
            </div>
          ))}
        </div>

        <div 
          className="search-bar"
          onClick={() => setShowAddPart(true)}
        >
          <input 
            type="text"
            placeholder="Search for parts..."
            readOnly
          />
        </div>

        <div className="button-container">
          <button className="cancel-btn" onClick={onClose}>
            Cancel
          </button>
          <button 
            className="confirm-btn" 
            onClick={handleConfirmClick}
            disabled={parts.length === 0}
          >
            Confirm
          </button>
        </div>
      </div>

      {showConfirmation && (
        <div className="confirmation-dialog">
          <div className="confirmation-content">
            <h2>⚠ Confirm Parts Request</h2>
            <p>You are about to send these items to the parts administrator. Are you sure?</p>
            <div className="button-container">
              <button 
                className="cancel-btn"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
              <button 
                className="confirm-btn"
                onClick={handleFinalConfirm}
              >
                Yes, Send Request
              </button>
            </div>
          </div>
        </div>
      )}

      {showAddPart && (
        <div className="add-part-modal">
          <div className="add-part-content">
            <h3>Add Part</h3>
            <div className="add-part-form">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search inventory..."
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  onFocus={() => setShowInventoryList(true)}
                  autoFocus
                />
                {showInventoryList && searchTerm && (
                  <div className="inventory-list">
                    {filteredInventory.map((item) => (
                      <div 
                        key={item.id} 
                        className="inventory-item"
                        onClick={() => handleSelectPart(item)}
                      >
                        <span className="item-code">{item.part_id}</span>
                        <span className="item-name">{item.item_name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {newPart.name && (
                <div className="selected-part">
                  <span>Selected: {newPart.name}</span>
                  <input
                    type="number"
                    placeholder="Quantity"
                    value={newPart.quantity}
                    onChange={(e) => setNewPart({...newPart, quantity: e.target.value})}
                  />
                </div>
              )}
            </div>
            <div className="button-container">
              <button 
                className="cancel-btn"
                onClick={() => {
                  setShowAddPart(false);
                  setSearchTerm('');
                  setNewPart({ name: '', quantity: '' });
                }}
              >
                Cancel
              </button>
              <button 
                className="confirm-btn"
                onClick={handleAddPart}
                disabled={!newPart.name || !newPart.quantity}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestPartsPopup; 