import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';
import './AssignItems.scss';

const AssignItems = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { job } = location.state || {};
  
  const [requiredItems, setRequiredItems] = useState([]);
  const [assignedItems, setAssignedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inventory, setInventory] = useState({});
  const [availableStock, setAvailableStock] = useState({});
  const [showAddItem, setShowAddItem] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [jobDetails, setJobDetails] = useState({
    type: '',
    repair_type: '',
    machinery_name: '',
    client_name: '',
    location: ''
  });
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [showTechnicianModal, setShowTechnicianModal] = useState(false);
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch all required data in parallel
        const [
          activityItemsResponse, 
          itemsResponse,
          activityResponse,
          machineryResponse,
          clientsResponse
        ] = await Promise.all([
          api.get(`/activities/${job.id}/items/`),
          api.get('/items/'),
          api.get(`/activities/${job.id}/`),
          api.get('/machinery/'),
          api.get('/clients/')
        ]);
        // Create maps for quick lookups
        const machineryMap = machineryResponse.data.reduce((acc, machinery) => {
          acc[machinery.id] = machinery;
          return acc;
        }, {});

        const clientsMap = clientsResponse.data.reduce((acc, client) => {
          acc[client.id] = client;
          return acc;
        }, {});

        const activity = activityResponse.data;
        const machinery = machineryMap[activity.id_machinery];
        const client = clientsMap[activity.id_client];
        console.log("activity", activity);
        console.log("machinery", machinery);
        console.log("client", client);  
        // Set job details
        setJobDetails({
          type: activity.type,
          repair_type: activity.type,
          machinery_name: machinery?.unit || 'Unknown Machine',
          client_name: client?.client_name || 'Unknown Client',
          location: client?.to_ship_location || 'Unknown Location'
        });

        // Create items map and handle inventory
        const itemsMap = itemsResponse.data.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});

        const inventoryMap = itemsResponse.data.reduce((acc, item) => {
          acc[item.id] = item.quantity;
          return acc;
        }, {});

        setInventory(inventoryMap);
        setAvailableStock(inventoryMap);
        setAllItems(itemsResponse.data);

        // Map activity items with their corresponding item details
        const assignedItemsWithDetails = activityItemsResponse.data.map(activityItem => ({
          id: activityItem.id,
          id_item: activityItem.id_item,
          part_id: itemsMap[activityItem.id_item]?.part_id || 'N/A',
          name: itemsMap[activityItem.id_item]?.item_name || 'Unknown Item',
          quantity: activityItem.quantity_assigned,
          quantity_requested: activityItem.quantity_requested,
          inventory_quantity: inventoryMap[activityItem.id_item] || 0
        }));

        // Separate into required and assigned based on quantity_requested
        const required = assignedItemsWithDetails.filter(item => item.quantity_requested !== null);
        const assigned = assignedItemsWithDetails.filter(item => item.quantity !== null);

        setRequiredItems(required);
        setAssignedItems(assigned);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [job.id]);

  const handleQuantityChange = (itemId, newQuantity) => {
    const item = assignedItems.find(item => item.id === itemId);
    const oldQuantity = item.quantity || 0;
    const parsedNewQuantity = parseInt(newQuantity) || 0;
    
    // Update assigned items
    setAssignedItems(prevItems => 
      prevItems.map(item => 
        item.id === itemId 
          ? { 
              ...item, 
              quantity: Math.min(Math.max(0, parsedNewQuantity), item.inventory_quantity)
            }
          : item
      )
    );

    // Update available stock
    setAvailableStock(prev => ({
      ...prev,
      [item.id_item]: inventory[item.id_item] - parsedNewQuantity
    }));
  };

  const handleAssign = () => {
    setShowTechnicianModal(true);
  };

  const fetchTechnicians = async () => {
    try {
      const [usersResponse, activityUsersResponse, groupsResponse] = await Promise.all([
        api.get('/users/'),
        api.get('/activity-users/'),
        api.get('/groups/')
      ]);

      // Get technician group ID
      const technicianGroupId = groupsResponse.data.find(
        group => group.name === 'Technician'
      )?.id;

      // Filter users to get only technicians
      const technicianUsers = usersResponse.data.filter(
        user => user.groups.includes(technicianGroupId)
      );

      console.log('All users:', usersResponse.data);
      console.log('Technician group ID:', technicianGroupId);
      console.log('Technicians filtered:', technicianUsers);

      // Map activity users to track assignments
      const activityUserMap = activityUsersResponse.data.reduce((acc, au) => {
        if (!acc[au.id_user]) {
          acc[au.id_user] = [];
        }
        acc[au.id_user].push(au);
        return acc;
      }, {});

      // Combine user data with their activity assignments
      const techniciansList = technicianUsers.map(tech => ({
        ...tech,
        assignments: activityUserMap[tech.id] || []
      }));

      console.log('Final technicians list:', techniciansList);

      setTechnicians(techniciansList);
    } catch (error) {
      console.error('Error fetching technicians:', error);
    }
  };

  const handleTechnicianSelect = async (technician) => {
    try {
      // Get the status ID for "In Process"
      const statusResponse = await api.get('/status/');
      const inProcessStatusId = statusResponse.data.find(
        status => status.name === 'In Process'
      )?.id;

      if (!inProcessStatusId) {
        throw new Error('Could not find In Process status');
      }

      // Update the activity status
      await api.patch(`/activities/${job.id}/`, {
        id_status: inProcessStatusId
      });

      // Create activity-user association
      await api.post('/activity-users/', {
        id_user: technician.id,
        id_activity: job.id,
        time_started: new Date().toISOString(),
        last_work: false,
        assigned_flag: true,
        is_active: true
      });

      // Assign items with correct structure
      const itemAssignments = assignedItems.map(item => ({
        quantity_assigned: parseInt(item.quantity),
        id_activity: parseInt(job.id),
        id_item: parseInt(item.id_item)
      }));

      // Send each item assignment individually
      for (const item of itemAssignments) {
        await api.post('/activity-items/', item);
      }

      navigate('/job');
    } catch (error) {
      console.error('Error assigning technician:', error);
      alert('Failed to assign technician. Please try again.');
    }
  };

  useEffect(() => {
    if (showTechnicianModal) {
      fetchTechnicians();
    }
  }, [showTechnicianModal]);

  const handleCancel = () => {
    navigate('/job');
  };

  const handleCloseJob = () => {
    setShowCloseConfirmation(true);
  };

  const confirmCloseJob = async () => {
    try {
      // Get the status ID for "Job Closed"
      const statusResponse = await api.get('/status/');
      const closedStatusId = statusResponse.data.find(
        status => status.name === 'Job Closed'
      )?.id;

      if (!closedStatusId) {
        throw new Error('Could not find Job Closed status');
      }

      // Get the activity-user record for this job
      const activityUsersResponse = await api.get(`/activities/${job.id}/users/`);
      const activityUser = activityUsersResponse.data.find(au => !au.time_finished);

      if (activityUser) {
        // Update the activity-user record
        await api.patch(`/activity-users/${activityUser.id}/`, {
          last_work: true,
          time_finished: new Date().toISOString()
        });
      }

      // Update the job status
      await api.patch(`/activities/${job.id}/`, {
        id_status: closedStatusId
      });

      navigate('/job');
    } catch (error) {
      console.error('Error closing job:', error);
      alert('Failed to close job. Please try again.');
    }
  };

  const handleRequestAdditional = () => {
    // Handle requesting additional parts logic here
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    const filtered = allItems.filter(item => 
      item.item_name.toLowerCase().includes(value.toLowerCase()) ||
      item.part_id.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleAddItem = (item) => {
    // Check if item already exists in assignedItems
    const isDuplicate = assignedItems.some(
      assignedItem => assignedItem.id_item === item.id
    );

    if (isDuplicate) {
      alert('This item is already in the list');
      return;
    }

    const newAssignedItem = {
      id: Date.now(), // temporary ID
      id_item: item.id,
      part_id: item.part_id,
      name: item.item_name,
      quantity: 0,
      inventory_quantity: item.quantity
    };
    
    setAssignedItems(prev => [...prev, newAssignedItem]);
    setAvailableStock(prev => ({
      ...prev,
      [item.id]: item.quantity
    }));
    setShowAddItem(false);
    setSearchTerm('');
  };

  const handleDeleteItem = (itemId) => {
    const item = assignedItems.find(item => item.id === itemId);
    setAssignedItems(prev => prev.filter(i => i.id !== itemId));
    setAvailableStock(prev => ({
      ...prev,
      [item.id_item]: inventory[item.id_item]
    }));
  };

  return (
    <div className="page-container">
      <Sidebar />
      <div className="assign-items-container">
        <div className="header">
          <div className="header-content">
            <div className="title">{jobDetails.type}</div>
            <div className="job-details">
              <div className="details-row">
                <div className="detail-item">
                  <span className="icon">🔧</span>
                  <span>{jobDetails.repair_type}</span>
                </div>
                <div className="detail-item">
                  <span className="icon">🚛</span>
                  <span>{jobDetails.machinery_name}</span>
                </div>
              </div>
              <div className="details-row">
                <div className="detail-item">
                  <span className="icon">📍</span>
                  <span>{jobDetails.location}</span>
                </div>
                <div className="detail-item">
                  <span className="icon">👤</span>
                  <span>{jobDetails.client_name}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="required-section">
            <h2>Required</h2>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Requested</th>
                </tr>
              </thead>
              <tbody>
                {requiredItems.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.quantity_requested}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="assigned-section">
            <div className="section-header">
              <h2>Assigned</h2>
              <button 
                className="add-item-btn"
                onClick={() => setShowAddItem(true)}
              >
                + Add Item
              </button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Part ID</th>
                  <th>Name</th>
                  <th>In Stock</th>
                  <th>Quantity</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {assignedItems.map((item) => (
                  <tr key={item.id} className={item.quantity > item.inventory_quantity ? 'error' : ''}>
                    <td>{item.part_id}</td>
                    <td>{item.name}</td>
                    <td className={availableStock[item.id_item] < 0 ? 'negative' : ''}>
                      {availableStock[item.id_item]}
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        max={item.inventory_quantity}
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                        className="quantity-input"
                      />
                    </td>
                    <td>
                      <button 
                        className="delete-btn"
                        onClick={() => handleDeleteItem(item.id)}
                      >
                        ×
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {showAddItem && (
          <div className="add-item-modal">
            <div className="modal-content">
              <h3>Add Item</h3>
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search items..."
                  value={searchTerm}
                  onChange={(e) => handleSearchChange(e.target.value)}
                  autoFocus
                />
                {searchTerm && (
                  <div className="items-list">
                    {filteredItems.map((item) => (
                      <div 
                        key={item.id}
                        className="item-option"
                        onClick={() => handleAddItem(item)}
                      >
                        <span className="item-code">{item.part_id}</span>
                        <span className="item-name">{item.item_name}</span>
                        <span className="item-stock">Stock: {item.quantity}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="modal-actions">
                <button 
                  className="cancel-btn"
                  onClick={() => {
                    setShowAddItem(false);
                    setSearchTerm('');
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="actions">
          <button className="cancel-btn" onClick={handleCancel}>
            Cancel
          </button>
          <button className="close-btn" onClick={handleCloseJob}>
            Close Job
          </button>
          <button className="assign-btn" onClick={handleAssign}>
            Assign
          </button>
        </div>

        {showCloseConfirmation && (
          <div className="modal-overlay">
            <div className="confirmation-modal">
              <h3>Close Job</h3>
              <p>Are you sure you want to close this job?</p>
              <div className="modal-actions">
                <button 
                  className="cancel-btn"
                  onClick={() => setShowCloseConfirmation(false)}
                >
                  Cancel
                </button>
                <button 
                  className="confirm-btn"
                  onClick={confirmCloseJob}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}

        {showTechnicianModal && (
          <div className="modal-overlay">
            <div className="technician-modal">
              <h3>Select Technician</h3>
              <div className="technicians-list">
                {technicians.map((tech) => (
                  <div 
                    key={tech.id}
                    className="technician-option"
                    onClick={() => handleTechnicianSelect(tech)}
                  >
                    <span className="tech-name">
                      {tech.first_name} {tech.last_name}
                    </span>
                    <span className="tech-assignments">
                      Active Jobs: {tech.assignments.length}
                    </span>
                  </div>
                ))}
              </div>
              <div className="modal-actions">
                <button 
                  className="cancel-btn"
                  onClick={() => setShowTechnicianModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignItems; 