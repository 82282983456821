import React, { useState } from 'react';
import './AddNotePopup.css';

const AddNotePopup = ({ isOpen, onClose, onConfirm, currentNote }) => {
  const [note, setNote] = useState(currentNote || '');

  if (!isOpen) return null;

  return (
    <div className="add-note-overlay">
      <div className="add-note-modal">
        <h2>Add Note</h2>
        <p>Add a note to document important information about this job.</p>
        
        <div className="note-content">
          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Write your note here..."
            rows="6"
          />
        </div>

        <div className="button-container">
          <button className="cancel-btn" onClick={onClose}>
            Cancel
          </button>
          <button 
            className="confirm-btn" 
            onClick={() => onConfirm(note)}
            disabled={!note.trim()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNotePopup; 