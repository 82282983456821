import React, { useState, useEffect } from 'react';
import '../../styles/job_module_style/JobModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';
import JobCard from '../../components/JobCard'; // Importa el componente JobCard
import icon1 from '../../assets/images/icon_delete.svg';
import icon2 from '../../assets/images/icon_edit.svg';
import icon3 from '../../assets/images/icon_add.svg';
import SearchIcon from '@mui/icons-material/Search';
import color_styles from '../../styles/_colors.scss';
import StartJobPopup from '../../components/StartJobPopup';
import { 
  TextField, 
  IconButton, 
  Box,
  Avatar,
  TablePagination
} from '@mui/material';
import InvoiceGenerationPopup from '../../components/InvoiceGenerationPopup';
import { useNavigate } from 'react-router-dom';


const JobModule = () => {
  const [technicians, setTechnicians] = useState([]);
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [techPage, setTechPage] = useState(0);
  const [techRowsPerPage, setTechRowsPerPage] = useState(7);

  const [jobs, setJobs] = useState([]);
  const [status, setStatus] = useState([]);
  const [machineries, setMachineries] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingJob, setEditingJob] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingJob, setDeletingJob] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [techSearchTerm, setTechSearchTerm] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [clients, setClients] = useState([]);
  const [selectedJobCard, setSelectedJobCard] = useState(null);
  const [startJobPopup, setStartJobPopup] = useState({ isOpen: false, job: null });
  const [showInvoice, setShowInvoice] = useState({ isOpen: false, job: null });

  // pagination of jobs
  const [currentPage, setCurrentPage] = useState(0);
  const jobsPerPage = 12; // 3 filas x 4 columnas

  const paginatedJobs = filteredJobs.slice(
    currentPage * jobsPerPage,
    (currentPage + 1) * jobsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);

  const getStatusIdByName = (statusName) => {
    console.log('Looking for status:', statusName);
    console.log('Available statuses:', status);
    const statusItem = status.find((item) => item.name === statusName);
    console.log('Status Item:', statusItem);
    return statusItem ? statusItem.id : null;
  };

  // Add new state for user role
  const userRole = localStorage.getItem('userRole');
  const isAdmin = userRole !== 'Technician';

  // Add a new state to track if status is ready
  const [statusReady, setStatusReady] = useState(false);

  const navigate = useNavigate();

  // Modify the fetchUnassignedJobs function to wait for status
  const fetchUnassignedJobs = async () => {
    console.log('Fetching unassigned jobs');
    try {
      // Wait until status is ready
      if (!statusReady) {
        console.log('Status not ready yet');
        return [];
      }

      // Get all activities
      const response = await api.get('/activities/');
      
      // Get the ID for "Job Unassigned" status
      const unassignedStatusId = getStatusIdByName("Job Unassigned");
      console.log('Unassigned Status ID:', unassignedStatusId);
      
      // Filter activities to get only unassigned jobs
      const unassignedJobs = response.data.filter(job => 
        job.id_status === unassignedStatusId
      );
      
      console.log('Filtered Unassigned Jobs:', unassignedJobs);
      return unassignedJobs;
    } catch (err) {
      console.error('Error fetching unassigned jobs:', err);
      return [];
    }
  };

  // Modify useEffect to handle status loading
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem('access');
        const userId = localStorage.getItem('userId');
        const userRole = localStorage.getItem('userRole');
        
        if (!accessToken) {
          console.error('No access token found');
          setError('Authentication error');
          return;
        }

        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        // First fetch status data
        const statusResponse = await api.get('/status/');
        setStatus(statusResponse.data);
        setStatusReady(true);
        console.log('Status data:', statusResponse.data);
        console.log('User Role:', userRole);

        if (userRole === 'Parts Administrator') {
          console.log("Parts Administrator view");
          // Parts Administrator view - fetch all data but filter jobs
          const [techResponse, jobsResponse, machineriesResponse, clientsResponse, groupsResponse] = await Promise.all([
            api.get('/users/'),
            api.get('/activities/'),
            api.get('/machinery/'),
            api.get('/clients/'),
            api.get('/groups/')
          ]);

          // Get technician group ID
          const technicianGroupId = getGroupIdByName(groupsResponse.data, 'Technician');

          // Filter technicians
          const filteredTechnicians = techResponse.data.filter(user => 
            user.groups && user.groups.some(group => group === technicianGroupId)
          );

          // Get Parts Ordered status ID
          const partsOrderedStatusId = getStatusIdByName("Parts Ordered");

          // Filter jobs to show only Parts Ordered status
          const partsOrderedJobs = jobsResponse.data.filter(job => 
            job.id_status === partsOrderedStatusId
          );

          setTechnicians(filteredTechnicians);
          setFilteredTechnicians(filteredTechnicians);
          setJobs(partsOrderedJobs);
          setFilteredJobs(partsOrderedJobs);
          setMachineries(machineriesResponse.data);
          setClients(clientsResponse.data);
          setGroups(groupsResponse.data);
          
          // Store all filtered jobs
          setAllJobs(partsOrderedJobs);

        } else if (isAdmin) {
          // Admin view - fetch all data as before
          const [techResponse, jobsResponse, machineriesResponse, clientsResponse, groupsResponse] = await Promise.all([
            api.get('/users/'),
            api.get('/activities/'),
            api.get('/machinery/'),
            api.get('/clients/'),
            api.get('/groups/')
          ]);

          // Get technician group ID
          const technicianGroupId = getGroupIdByName(groupsResponse.data, 'Technician');

          // Filter technicians
          const filteredTechnicians = techResponse.data.filter(user => 
            user.groups && user.groups.some(group => group === technicianGroupId)
          );

          const jobClosedStatusId = getStatusIdByName("Job Closed");

          // Filter out jobs with Job Closed status
          const activeJobs = jobsResponse.data.filter(job => 
            job.id_status !== jobClosedStatusId
          );

          console.log(activeJobs);

          setTechnicians(filteredTechnicians);
          setFilteredTechnicians(filteredTechnicians);
          setJobs(activeJobs);
          setFilteredJobs(activeJobs);
          setMachineries(machineriesResponse.data);
          setClients(clientsResponse.data);
          setGroups(groupsResponse.data);
          
          // almacena todos los jobs
          setAllJobs(jobsResponse.data);

        } else {
          try {
            // Get all necessary data
            const [userActivitiesResponse, activitiesResponse, machineriesResponse] = await Promise.all([
              api.get(`/users/${userId}/activities/`),
              api.get('/activities/'),
              api.get('/machinery/')
            ]);

            console.log('Machineries:', machineriesResponse.data);

            // Get status IDs for allowed statuses
            const allowedStatusNames = ["Job Assigned", "In Process", "Job Pause"];
            const allowedStatusIds = statusResponse.data
              .filter(status => allowedStatusNames.includes(status.name))
              .map(status => status.id);

            // Filter active activities for this user with allowed statuses and assigned_flag
            const userActivityIds = userActivitiesResponse.data
              .filter(ua => ua.is_active && ua.assigned_flag === true)
              .map(ua => ua.id_activity);

            // Get full activity details for user's activities with allowed statuses
            const userActivities = activitiesResponse.data
              .filter(activity => 
                userActivityIds.includes(activity.id) && 
                allowedStatusIds.includes(activity.id_status)
              );

            console.log('Filtered User Activities:', userActivities);

            setJobs(userActivities);
            setMachineries(machineriesResponse.data);
            setFilteredJobs(userActivities);
          } catch (err) {
            if (err.response?.status === 404) {
              setJobs([]);
              setFilteredJobs([]);
            } else {
              throw err;
            }
          }
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        if (err.response?.status === 401) {
          setError('Authentication error. Please log in again.');
        } else if (!isAdmin && err.response?.status === 404) {
          setError('No jobs are currently assigned to you.');
        } else {
          setError('Error fetching data');
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [isAdmin]);

  // Add a useEffect to refetch unassigned jobs when status changes
  useEffect(() => {
    if (statusReady && !isAdmin) {
      fetchUnassignedJobs().then(jobs => {
        setTechnicians(jobs);
        setFilteredTechnicians(jobs);
      });
    }
  }, [statusReady]);

  const getJobTechnician = async (jobId) => {
    try {
      const response = await api.get(`/activity-users/?id_activity=${jobId}`);
      if (response.data && response.data.length > 0) {
        const activityUser = response.data[0];
        // Only return the technician ID if the association is active
        return activityUser.is_active ? activityUser.id_user : '';
      }
      return '';
    } catch (err) {
      console.error('Error fetching technician for job:', err);
      return '';
    }
  };

  const deactivateTechnician = async (jobId) => {
    try {
      const response = await api.get(`/activity-users/?id_activity=${jobId}`);
      if (response.data && response.data.length > 0) {
        const activityUserId = response.data[0].id;
        await api.post(`/activity-users/${activityUserId}/deactivate/`);
        console.log('Technician deactivated successfully');
      }
    } catch (err) {
      console.error('Error deactivating technician:', err);
      throw err;
    }
  };

  const handleEdit = async () => {
    if (selectedJobCard) {
      try {
        // Get the current technician assigned to this job
        console.log('Selected Job Card ID:', selectedJobCard.id);
        const activityUsersResponse = await api.get(`/activities/${selectedJobCard.id}/users/`);
        console.log('All activity-users:', activityUsersResponse.data);
        
        const currentTechnician = activityUsersResponse.data.find(au => 
          au.assigned_flag === true && au.is_active === true
        );
        
        console.log('Current Active Technician:', currentTechnician);

        // Store current technician ID in localStorage
        if (currentTechnician) {
          localStorage.setItem('previousTechnicianId', currentTechnician.id_user.toString());
          const technicianResponse = await api.get(`/users/${currentTechnician.id_user}/`);
          console.log('Technician Details:', technicianResponse.data);
        } else {
          localStorage.setItem('previousTechnicianId', '');
        }

        const technicianId = currentTechnician ? currentTechnician.id_user.toString() : '';
        console.log('Setting technician ID:', technicianId);

        setEditingJob({
          ...selectedJobCard,
          technician_id: technicianId
        });
        setIsEditing(true);
      } catch (err) {
        console.error('Error fetching technician for job:', err);
        localStorage.setItem('previousTechnicianId', '');
        setEditingJob({
          ...selectedJobCard,
          technician_id: ''
        });
        setIsEditing(true);
      }
    }
  };

  const handleCreate = () => {
    setEditingJob({
      id_client: '',
      id_machinery: '',
      id_status: '1',
      type: '',
      description: '',
      work_date: new Date().toISOString().split('T')[0],
      frequency: '',
      technician_id: ''
    });
    setIsCreating(true);
  };

  const handleDelete = () => {
    if (selectedJobCard) {
      setDeletingJob(selectedJobCard);
      setIsDeleting(true);
    }
  };

  const handleEditChange = (e) => {
    setEditingJob({ ...editingJob, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      /* // Get previous technician ID from localStorage
      const previousTechnicianId = localStorage.getItem('previousTechnicianId');
      console.log('Previous Technician ID:', previousTechnicianId);
      
      // Log current technician information before making changes
      console.log('Job being edited/created:', editingJob.id);
      
      const activityUsersResponse = await api.get(`/activities/${editingJob.id}/users/`);
      console.log('All activity-users:', activityUsersResponse.data);
      
      const currentTechnician = activityUsersResponse.data.find(au => 
        au.assigned_flag === true && au.is_active === true
      );
      
      console.log('Current Active Technician:', currentTechnician);

      if (currentTechnician) {
        const technicianResponse = await api.get(`/users/${currentTechnician.id_user}/`);
        console.log('Current Technician Details:', technicianResponse.data);
      }
      console.log('New Technician ID being assigned:', editingJob.technician_id);
 */
      const jobData = {
        id_client: editingJob.id_client,
        id_machinery: editingJob.id_machinery,
        id_status: editingJob.technician_id ? 
          getStatusIdByName("Job Assigned") : 
          getStatusIdByName("Job Unassigned"),
        type: editingJob.type,
        description: editingJob.description,
        work_date: editingJob.work_date,
        frequency: editingJob.frequency
      };
      console.log('Job Data:', jobData);

      if (isCreating) {
        const createResponse = await api.post('/activities/', jobData);
        console.log('New Job Response:', createResponse.data);
        
        // Create activity-user association if technician was selected
        console.log('Editing Job Technician ID:', editingJob.technician_id);
        if (editingJob.technician_id) {
          const activityUserData = {
            id_user: editingJob.technician_id,
            id_activity: String(createResponse.data.id),
            last_work: false,
            assigned_flag: true,
            is_active: true
          };
          console.log('Activity-User Data:', activityUserData);
          const activityUserResponse = await api.post('/activity-users/', activityUserData);
          console.log('Activity-User Association Response:', activityUserResponse.data);
        }
        
        setConfirmationMessage('Job created successfully');
      } else {
        // For editing, check if technician was removed
        const currentTechId = await getJobTechnician(editingJob.id);
        if (currentTechId && !editingJob.technician_id) {
          // Technician was removed, deactivate the association
          await deactivateTechnician(editingJob.id);
        } else if (editingJob.technician_id) {
          // Technician was added or changed
          const activityUserData = {
            id_user: editingJob.technician_id,
            id_activity: editingJob.id,
            last_work: false,
            assigned_flag: true,
            is_active: true
          };
          await api.post('/activity-users/', activityUserData);
        }
        
        const previousTechnicianId = localStorage.getItem('previousTechnicianId');
        if (previousTechnicianId) {
          console.log('Previous Technician ID:', previousTechnicianId);
          const userActivitiesResponse = await api.get(`/users/${previousTechnicianId}/activities/`);
          const activityToUpdate = userActivitiesResponse.data.find(
            ua => ua.id_activity === editingJob.id && ua.assigned_flag === true
          );

          if (activityToUpdate) {
            // Update assigned_flag to false for the previous technician
            await api.patch(`/activity-users/${activityToUpdate.id}/`, {
              assigned_flag: false
            });
          }
        }
        await api.put(`/activities/${editingJob.id}/`, jobData);
        setConfirmationMessage('Job updated successfully');
      }

      const response = await api.get('/activities/');
      setJobs(response.data);
      setFilteredJobs(response.data);
      setIsEditing(false);
      setIsCreating(false);
      setEditingJob(null);
      setShowConfirmation(true);

      // Clear localStorage after successful save
      localStorage.removeItem('previousTechnicianId');
    } catch (err) {
      setError('Error saving data');
      console.error('Error:', err);
      // Clear localStorage on error
      localStorage.removeItem('previousTechnicianId');
    }
  };

  const confirmDelete = async () => {
    try {
      await api.post(`/activities/${deletingJob.id}/deactivate/`);
      const response = await api.get('/activities/');
      setJobs(response.data);
      setFilteredJobs(response.data);
      setIsDeleting(false);
      setDeletingJob(null);
      setSelectedJob(null);
      setConfirmationMessage('Job deleted successfully');
      setShowConfirmation(true);
    } catch (err) {
      setError('Error deleting data');
    }
  };

  const handleTechnicianClick = async (techId) => {
    try {
      const isDeselecting = techId === selectedJob;
      setSelectedJob(isDeselecting ? null : techId);
      
      // If user is not admin (is technician), don't filter
      if (!isAdmin) {
        return;
      }
      
      if (!isDeselecting && techId) {
        // For unassigned jobs, we don't want to filter
        const unassignedStatusId = getStatusIdByName("Job Unassigned");
        const clickedJob = jobs.find(job => job.id === techId);
        
        if (clickedJob && clickedJob.id_status === unassignedStatusId) {
          // Don't filter for unassigned jobs
          return;
        }

        // Get jobs for the selected technician
        const response = await api.get(`/users/${techId}/activities/`);
        const activityIds = response.data.map(item => item.id_activity);
        const filtered = allJobs.filter(job_uni => activityIds.includes(job_uni.id));
        setFilteredJobs(filtered);
      } else {
        // If deselecting or clicking unassigned job, show all jobs
        setFilteredJobs(jobs);
      }
      
      // Reset to first page when changing filters
      setCurrentPage(0);
    } catch (err) {
      console.error('Error fetching technician jobs:', err);
      setFilteredJobs(jobs); // On error, show all jobs
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
  
    const filtered = jobs.filter(job => {
      const jobTitle = job.type?.toLowerCase() || '';
      const jobDescription = job.description?.toLowerCase() || '';
      const machineryInfo = getMachineryInfoById(job.id_machinery)?.toLowerCase() || '';
  
      return (
        jobTitle.includes(term) || 
        jobDescription.includes(term) || 
        machineryInfo.includes(term)
      );
    });
  
    setFilteredJobs(filtered);
  };

  const handleTechnicianSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setTechSearchTerm(searchTerm);

    if (isAdmin) {
      // Admin view - filter technicians
      const filtered = technicians.filter(tech => {
        const fullName = `${tech.first_name} ${tech.last_name}`.toLowerCase();
        return fullName.includes(searchTerm);
      });
      setFilteredTechnicians(filtered);
    } else {
      // Technician view - filter unassigned jobs
      const unassignedStatusId = getStatusIdByName("Job Unassigned");
      const filtered = jobs.filter(job => {
        // First ensure it's an unassigned job
        if (job.id_status !== unassignedStatusId || !job.is_active) {
          return false;
        }

        const jobType = job.type?.toLowerCase() || '';
        const jobDescription = job.description?.toLowerCase() || '';
        const machineryInfo = getMachineryInfoById(job.id_machinery)?.toLowerCase() || '';
        const client = clients.find(c => c.id === job.id_client);
        const clientName = client?.client_name?.toLowerCase() || '';
        const clientLocation = client?.to_ship_location?.toLowerCase() || '';
        
        return (
          jobType.includes(searchTerm) || 
          jobDescription.includes(searchTerm) || 
          machineryInfo.includes(searchTerm) ||
          clientName.includes(searchTerm) ||
          clientLocation.includes(searchTerm)
        );
      });
      setFilteredTechnicians(filtered);
    }
  };

  const getStatusColor = (job_status) => {
    switch (job_status) {
      case "Job Unassigned":
        return color_styles.jobUnassignedColor;
      case "Job Assigned":
        return color_styles.jobAssignedColor;
      case "Parts Ordered":
        return color_styles.jobPartsOrderedColor;
      case "In Process":
        return color_styles.jobInProcessColor;
      case "Repair Completed":
        return color_styles.jobRepairCompleteColor;
      case "Parts Approval":
        return color_styles.jobPartsApprovalColor;
      case "PO Required":
        return color_styles.jobPORequiredColor;
      case "MGMT Approval Needed":
        return color_styles.jobMGMTApprovalNeedColor;
      case "Job Closed":
        return color_styles.jobClosedColor;
      case "Job Pause":
        return color_styles.jobPause;
      default:
        return "#757575"; // Color por defecto
    }
  };

  const getStatusNameById = (id_status) => {
    const select_status = status.find((item) => item.id === id_status);
    return select_status ? select_status.name : "Status not found";
  };

  const getGroupIdByName = (groups, groupName) => {
    const group = groups.find(group => group.name === groupName);
    return group ? group.id : null;
  };

  const handleTechPageChange = (event, newPage) => {
    setTechPage(newPage);
  };

  const handleTechRowsPerPageChange = (event) => {
    setTechRowsPerPage(parseInt(event.target.value, 10));
    setTechPage(0);
  };

  const getMachineryInfoById = (machineryId) => {
    //console.log('Getting machinery info for ID:', machineryId);
    //console.log('Available machineries:', machineries);
    
    const machinery = machineries.find((item) => item.id === machineryId);
    //console.log('Found machinery:', machinery);
    
    if (machinery) {
      const info = `${machinery.unit} ${machinery.model} | ${machinery.serial_number}`;
      //console.log('Returning machinery info:', info);
      return info;
    } else {
      //console.log('Machinery not found for ID:', machineryId);
      return "Machinery not found";
    }
  };

  const handleJobCardClick = (job) => {
    setSelectedJobCard(selectedJobCard?.id === job.id ? null : job);
  };

  const [showStartJobWarning, setShowStartJobWarning] = useState(false);
  const [selectedJobToStart, setSelectedJobToStart] = useState(null);

  const handleJobCardDoubleClick = (job) => {
    if (isAdmin) {
      // Get the status name for the job
      const jobStatus = getStatusNameById(job.id_status);
      
      if (jobStatus === "MGMT Approval Needed") {
        // Show confirmation popup first
        setStartJobPopup({ isOpen: true, job: job });
      } else {
        // Existing logic for other statuses
        setSelectedJobForItems(job);
        setShowAssignItemsDialog(true);
      }
    } else {
      // Existing technician logic
      setSelectedJobToStart(job);
      setShowStartJobWarning(true);
    }
  };

  const confirmStartJob = async () => {
    try {
      // Get the status ID for "In Process"
      const response = await api.get('/status/');
      const inProcessStatus = response.data.find(status => status.name === "In Process");
      
      if (!inProcessStatus) {
        throw new Error("Could not find 'In Process' status");
      }

      // Update job status to "In Process"
      await api.put(`/activities/${selectedJobToStart.id}/`, {
        ...selectedJobToStart,
        id_status: inProcessStatus.id
      });

      // Navigate to active job view
      navigate('/active-job', { 
        state: { job: selectedJobToStart }
      });
    } catch (error) {
      console.error('Error starting job:', error);
      alert('Error starting job. Please try again.');
    } finally {
      setShowStartJobWarning(false);
    }
  };

  const cancelStartJob = () => {
    setShowStartJobWarning(false);
    setSelectedJobToStart(null);
  };

  const handleStartJobCancel = () => {
    setStartJobPopup({ isOpen: false, job: null });
  };

  const handleStartJobConfirm = () => {
    setShowInvoice({ isOpen: true, job: startJobPopup.job });
    setStartJobPopup({ isOpen: false, job: null });
  };

  const handleInvoiceBack = () => {
    setShowInvoice({ isOpen: false, job: null });
  };

  const handleCreateInvoice = () => {
    // Add invoice creation logic here
    setShowInvoice({ isOpen: false, job: null });
  };

  // Add this useEffect to filter technicians whenever techSearchTerm or technicians array changes
  useEffect(() => {
    const filtered = technicians.filter(tech => {
      const fullName = `${tech.first_name} ${tech.last_name}`.toLowerCase();
      return fullName.includes(techSearchTerm.toLowerCase());
    });
    setFilteredTechnicians(filtered);
  }, [techSearchTerm, technicians]);

  // Add new function to handle job assignment
  const handleAssignToMe = async (job) => {
    try {
      const userId = localStorage.getItem('userId');
      
      // First update the job status to "Job Assigned"
      const assignedStatusId = getStatusIdByName("Job Assigned");
      await api.put(`/activities/${job.id}/`, {
        ...job,
        id_status: assignedStatusId
      });

      // Create activity-user association
      const activityUserData = {
        id_user: userId,
        id_activity: job.id,
        last_work: false
      };
      await api.post('/activity-users/', activityUserData);

      // Refresh the unassigned jobs list
      const unassignedJobs = await fetchUnassignedJobs();
      setTechnicians(unassignedJobs);
      setFilteredTechnicians(unassignedJobs);

      // Refresh the technician's assigned jobs - modified to get full job details
      const [userActivitiesResponse, activitiesResponse] = await Promise.all([
        api.get(`/users/${userId}/activities/`),
        api.get('/activities/')
      ]);

      // Filter active activities for this user
      const userActivityIds = userActivitiesResponse.data
        .filter(ua => ua.is_active)
        .map(ua => ua.id_activity);

      // Get full activity details for user's activities
      const userActivities = activitiesResponse.data
        .filter(activity => userActivityIds.includes(activity.id));

      setJobs(userActivities);
      setFilteredJobs(userActivities);

      // Show success message
      setConfirmationMessage('Job successfully assigned');
      setShowConfirmation(true);
    } catch (err) {
      console.error('Error assigning job:', err);
      setError('Error assigning job');
    }
  };

  // Modify the left panel rendering
  const renderLeftPanel = () => {
    if (isAdmin) {
      {/* ----------------------------------------------  Barra izquierda si es Administrador  ----------------------------------------------------------------------- */}
      return (
        <div className="technician-container">
          <div className="technician-search">
            <TextField
              fullWidth
              placeholder="Search technicians..."
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton className="search-button">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              className="tech-search-input"
              onChange={handleTechnicianSearch}
              value={techSearchTerm}
            />
          </div>
          <div className="technicians-panel">
            <h2>Technicians</h2>
            <div className="technicians-list">
              {filteredTechnicians
                .slice(techPage * techRowsPerPage, techPage * techRowsPerPage + techRowsPerPage)
                .map(tech => (
                  <div 
                    key={tech.id}
                    className={`technician-card ${selectedJob === tech.id ? 'selected' : ''}`}
                    onClick={() => handleTechnicianClick(tech.id)}
                  >
                    <Avatar className="tech-avatar">
                      {`${tech.first_name[0]}${tech.last_name[0]}`}
                    </Avatar>
                    <div className="tech-info">
                      <h3>{`${tech.first_name} ${tech.last_name}`}</h3>
                    </div>
                  </div>
                ))}
            </div>
            <TablePagination
              component="div"
              count={filteredTechnicians.length}
              page={techPage}
              onPageChange={handleTechPageChange}
              rowsPerPage={techRowsPerPage}
              onRowsPerPageChange={handleTechRowsPerPageChange}
              rowsPerPageOptions={[5, 7, 12]}
            />
          </div>
        </div>
      );
    } else {
      {/* ----------------------------------------------  Barra izquierda si es Technician  ----------------------------------------------------------------------- */}
      return (
        <div className="unassigned-jobs-container">
          <div className="unassigned-jobs-search">
            <TextField
              fullWidth
              placeholder="Search unassigned jobs..."
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton className="search-button">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              className="unassigned-search-input"
              onChange={handleTechnicianSearch}
              value={techSearchTerm}
            />
          </div>
          <div className="unassigned-jobs-panel">
            <h2>Unassigned Jobs</h2>
            <div className="unassigned-jobs-list">
              {filteredTechnicians
                .slice(techPage * techRowsPerPage, techPage * techRowsPerPage + techRowsPerPage)
                .map(job => (
                  <div 
                    key={job.id}
                    className={`unassigned-job-card ${selectedJob === job.id ? 'selected' : ''}`}
                  >
                    <div className="job-info" onClick={() => handleTechnicianClick(job.id)}>
                      <h3>{job.type}</h3>
                      <p>{getMachineryInfoById(job.id_machinery)}</p>
                      <p className="job-date">{job.work_date}</p>
                    </div>
                    <button
                      className="assign-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAssignToMe(job);
                      }}
                    >
                      Assign to me
                    </button>
                  </div>
                ))}
            </div>
            <TablePagination
              component="div"
              count={filteredTechnicians.length}
              page={techPage}
              onPageChange={handleTechPageChange}
              rowsPerPage={techRowsPerPage}
              onRowsPerPageChange={handleTechRowsPerPageChange}
              rowsPerPageOptions={[5, 7, 12]}
            />
          </div>
        </div>
      );
    }
  };

  const [showAssignItemsDialog, setShowAssignItemsDialog] = useState(false);
  const [selectedJobForItems, setSelectedJobForItems] = useState(null);

  const handleAssignItems = () => {
    navigate('/assign-items', { 
      state: { job: selectedJobForItems }
    });
  };

  return (
    <div className="job-module-container">
      <Sidebar userType={isAdmin ? "admin" : "technician"} />
      <div className="job-module">
        <div className="module-title">
          <h1>Job Module</h1>
        </div>
        <div className="main-container">
          {/* ----------------------------------------------  Barra izquierda segun rol  ----------------------------------------------------------------------- */}
          {renderLeftPanel()}

          {/* ------------------------------------------------  Barra derecha de jobs  ------------------------------------------------------------------------- */}
          <div className="jobs-container">
            <Box className="action-container">
              <TextField
                fullWidth
                placeholder="Search..."
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton className="search-button">
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                className="search-input"
                onChange={handleSearch}
              />
              {isAdmin && ( // Only show action buttons for admin
                <>
                  <IconButton 
                    className="delete-button" 
                    onClick={handleDelete}
                    disabled={!selectedJobCard}
                  >
                    <img src={icon1} alt="Delete" />
                  </IconButton>
                  <IconButton 
                    className="edit-button" 
                    onClick={handleEdit}
                    disabled={!selectedJobCard}
                  >
                    <img src={icon2} alt="Edit" />
                  </IconButton>
                  <IconButton className="add-button" onClick={handleCreate}>
                    <img src={icon3} alt="Add" />
                  </IconButton>
                </>
              )}
            </Box>

            <div className="jobs-panel">
              {filteredJobs.length === 0 ? (
                <div className="no-jobs-message">
                  <h3>No Jobs Found</h3>
                  <p>There are currently no jobs matching your search criteria.</p>
                </div>
              ) : (
                <div className="jobs-grid">
                  {paginatedJobs.map((job) => (
                    <JobCard
                      key={job.id}
                      jobName={job.type}
                      status={getStatusNameById(job.id_status)}
                      description={job.description}
                      machinery={getMachineryInfoById(job.id_machinery)}
                      date={job.work_date}
                      statusColor={getStatusColor(getStatusNameById(job.id_status))}
                      isSelected={selectedJobCard?.id === job.id}
                      onClick={() => handleJobCardClick(job)}
                      onDoubleClick={() => handleJobCardDoubleClick(job)}
                    />
                  ))}
                </div>
              )}
            </div>

            <div className="pagination">
              <button
                disabled={currentPage === 0}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
              <span>
                Page {currentPage + 1} of {totalPages}
              </span>
              <button
                disabled={currentPage === totalPages - 1}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        {(isEditing || isCreating) && (
          <div className="edit-popup">
            <div className="edit-popup-content">
              <h2>{isCreating ? 'Create Job' : 'Edit Job'}</h2>
              {error && <div className="error-message">{error}</div>}
              <div className="edit-form">
                <div className="form-row">
                  <div className="form-group">
                    <label>Client & Location</label>
                    <select
                      name="id_client"
                      value={editingJob.id_client}
                      onChange={handleEditChange}
                    >
                      <option value="">Select client and location</option>
                      {clients.map(client => (
                        <option key={client.id} value={client.id}>
                          {`${client.client_name} - ${client.to_ship_location}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Machinery</label>
                    <select
                      name="id_machinery"
                      value={editingJob.id_machinery}
                      onChange={handleEditChange}
                    >
                      <option value="">Select machinery</option>
                      {machineries.map(machine => (
                        <option key={machine.id} value={machine.id}>
                          {`${machine.unit} - ${machine.model} - ${machine.serial_number}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label>Type of Service</label>
                    <input
                      type="text"
                      name="type"
                      value={editingJob.type}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Frequency</label>
                    <select
                      name="frequency"
                      value={editingJob.frequency}
                      onChange={handleEditChange}
                    >
                      <option value="">Select frequency</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="biweekly">Biweekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="unique">Unique</option>
                    </select>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label>Technician</label>
                    <select
                      name="technician_id"
                      value={editingJob.technician_id}
                      onChange={handleEditChange}
                    >
                      <option value="">Select technician</option>
                      {technicians.map(tech => (
                        <option key={tech.id} value={tech.id}>
                          {`${tech.first_name} ${tech.last_name}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Date</label>
                    <input
                      type="date"
                      name="work_date"
                      value={editingJob.work_date}
                      onChange={handleEditChange}
                    />
                  </div>
                </div>

                <div className="form-group full-width">
                  <label>Description</label>
                  <textarea
                    name="description"
                    value={editingJob.description}
                    onChange={handleEditChange}
                    rows="4"
                  />
                </div>
              </div>

              <div className="edit-buttons">
                <button className="cancel-btn" onClick={() => {
                  setIsEditing(false);
                  setIsCreating(false);
                  setEditingJob(null);
                  setError('');
                }}>Cancel</button>
                <button className="save-btn" onClick={handleSave}>Save</button>
              </div>
            </div>
          </div>
        )}

        {isDeleting && (
          <div className="delete-alert">
            <div className="delete-alert-content">
              <h2>⚠ Alert</h2>
              <p>Do you want to delete this job?</p>
              <div className="delete-buttons">
                <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
                <button className="ok-btn" onClick={confirmDelete}>Ok</button>
              </div>
            </div>
          </div>
        )}

        {showConfirmation && (
          <div className="confirmation-popup">
            <div className="confirmation-popup-content">
              <h2>✓ Done</h2>
              <p>{confirmationMessage}</p>
              <button className="ok-btn" onClick={() => setShowConfirmation(false)}>Ok</button>
            </div>
          </div>
        )}

        {!isAdmin && (
          <StartJobPopup
            isOpen={startJobPopup.isOpen}
            job={startJobPopup.job}
            onCancel={handleStartJobCancel}
            onConfirm={handleStartJobConfirm}
          />
        )}

        {showInvoice.isOpen && (
          <InvoiceGenerationPopup
            isOpen={showInvoice.isOpen}
            job={showInvoice.job}
            onClose={() => setShowInvoice({ isOpen: false, job: null })}
            onConfirm={handleCreateInvoice}
            onBack={handleInvoiceBack}
          />
        )}

        {showStartJobWarning && (
          <div className="delete-alert">
            <div className="delete-alert-content">
              <h2>⚠ Alert</h2>
              <p>Are you sure you want to start working on this job?</p>
              <div className="delete-buttons">
                <button 
                  className="cancel-btn" 
                  onClick={cancelStartJob}
                >
                  Cancel
                </button>
                <button 
                  className="ok-btn" 
                  onClick={confirmStartJob}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        {showAssignItemsDialog && (
          <div className="delete-alert">
            <div className="delete-alert-content">
              <h2>⚠ Assign Items</h2>
              <p>Do you want to assign items to this job?</p>
              <div className="delete-buttons">
                <button 
                  className="cancel-btn" 
                  onClick={() => setShowAssignItemsDialog(false)}
                >
                  Cancel
                </button>
                <button 
                  className="ok-btn" 
                  onClick={handleAssignItems}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        {startJobPopup.isOpen && (
          <div className="modal-overlay">
            <div className="confirmation-modal">
              <h3>Start Invoice Generation</h3>
              <p>Are you sure you want to generate an invoice for this job?</p>
              <div className="modal-actions">
                <button 
                  className="cancel-btn"
                  onClick={handleStartJobCancel}
                >
                  Cancel
                </button>
                <button 
                  className="confirm-btn"
                  onClick={handleStartJobConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobModule;
