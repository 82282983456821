import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/StartJobPopup.scss';

const StartJobPopup = ({ job, onCancel, onConfirm, isOpen }) => {

  if (!isOpen) return null;

  return (
    <div className="start-job-popup">
      <div className="popup-content">
        <h2>{job.type}</h2>
        <div className="job-details">
          <p><strong>Description:</strong> {job.description}</p>
          <p><strong>Machinery:</strong> {job.machinery}</p>
          <p><strong>Location:</strong> {job.location}</p>
        </div>
        <p className="confirmation-text">Do you want to start {job.type}?</p>
        <div className="button-container">
          <button className="cancel-btn" onClick={onCancel}>Cancel</button>
          <button className="confirm-btn" onClick={onConfirm}>Ok</button>
        </div>
      </div>
    </div>
  );
};

StartJobPopup.propTypes = {
  job: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};


export default StartJobPopup;
