import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/components/InvoiceGenerationPopup.scss';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, IconButton } from '@mui/material';
import api from '../utils/api';
import quickbooksService from '../services/quickbooksService';
import icon1 from '../assets/images/icon_delete.svg';
import icon2 from '../assets/images/icon_edit.svg';
import icon3 from '../assets/images/icon_add.svg';

import icon_machine from '../assets/images/icon_machinery_module.svg';
import icon_client from '../assets/images/icon_clients_module.svg';
import icon_email from '../assets/images/icon_email.svg';
import icon_job from '../assets/images/icon_job_module.svg';


const InvoiceGenerationPopup = ({ job, onBack, onCreateInvoice, isOpen }) => {
  const [clientData, setClientData] = useState(null);
  const [machineryData, setMachineryData] = useState(null);
  const [jobItems, setJobItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [newRow, setNewRow] = useState({
    type: '',
    name: '',
    description: '',
    quantity: '',
    rate: '',
  });
  const [selectedTaxRate, setSelectedTaxRate] = useState(0);

  const FLORIDA_TAX_OPTIONS = [
    { value: 0, label: 'No Tax (0%)' },
    { value: 0.06, label: 'FL State Sales Tax (6%)' },
    { value: 0.07, label: 'FL + Local Tax (7%)' },
    { value: 0.0725, label: 'Miami-Dade County (7.25%)' },
  ];

  useEffect(() => {
    const fetchRelatedData = async () => {
      if (!job) return;
      
      try {
        setLoading(true);
        // Fetch client, machinery and job items data in parallel
        const [clientResponse, machineryResponse, itemsResponse] = await Promise.all([
          api.get(`/clients/${job.id_client}/`),
          api.get(`/machinery/${job.id_machinery}/`),
          api.get(`/activities/${job.id}/items/`)
        ]);

        setClientData(clientResponse.data);
        setMachineryData(machineryResponse.data);
        setJobItems(itemsResponse.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching related data:', err);
        setError('Error loading data');
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedData();
  }, [job]);

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const REALM_ID = "9341453744237632";
        const response = await api.get(`/v3/company/${REALM_ID}/query`, {
          params: {
            query: "select * from Item where name = 'Concrete'",
            minorversion: 75
          },
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          baseURL: process.env.NODE_ENV === 'production' 
            ? 'https://quickbooks.api.intuit.com'
            : 'https://quickbooks.api.intuit.com'
        });

        console.log('QuickBooks Item Query Response:', response.data);
      } catch (error) {
        console.error('Error fetching QuickBooks item:', error.response?.data || error);
      }
    };

    fetchItemDetails();
  }, []);

  const handleQuickBooksConnect = async () => {
    try {
      console.log('Starting QuickBooks connection process');
      const result = await quickbooksService.completeQuickBooksConnection(job.id);
      console.log('QuickBooks connection result:', result);
    } catch (error) {
      console.error('Error connecting to QuickBooks:', error);
      // Handle error appropriately
    }
  };

  const handleCreateInvoice = async () => {
    try {
      const REALM_ID = "9341453744237632";
      
      // Log initial data
      console.log('Creating invoice with data:', {
        job,
        clientData,
        machineryData,
        jobItems,
        selectedTaxRate
      });

      // Prepare and log line items
      const lineItems = jobItems.map(item => ({
        DetailType: "SalesItemLineDetail",
        Amount: item.quantity * item.rate,
        Description: item.description,
        SalesItemLineDetail: {
          ItemRef: {
            name: item.name,
            value: item.id_item.toString()
          },
          UnitPrice: item.rate,
          Qty: item.quantity,
          TaxCodeRef: {
            value: selectedTaxRate > 0 ? "TAX" : "NON"
          }
        }
      }));
      console.log('Prepared line items:', lineItems);

      // Prepare invoice data
      const invoiceData = {
        Line: lineItems,
        CustomerRef: {
          value: job.id_client.toString()
        },
        BillAddr: {
          Line1: clientData.billing_address,
          City: clientData.city,
          Country: "United States",
          CountrySubDivisionCode: "FL",
          PostalCode: clientData.zip_code
        },
        ShipAddr: {
          Line1: clientData.shipping_address,
          City: clientData.city,
          Country: "United States",
          CountrySubDivisionCode: "FL",
          PostalCode: clientData.zip_code
        },
        TxnDate: new Date().toISOString().split('T')[0],
        DueDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        TotalAmt: calculateSubtotal() * (1 + selectedTaxRate),
        ApplyTaxAfterDiscount: false,
        PrintStatus: "NeedToPrint",
        EmailStatus: "NotSet",
        BillEmail: {
          Address: clientData.email
        },
        CustomField: [
          {
            DefinitionId: "1",
            Name: "Job ID",
            Type: "StringType",
            StringValue: job.id.toString()
          },
          {
            DefinitionId: "2",
            Name: "Job Type",
            Type: "StringType",
            StringValue: job.type
          },
          {
            DefinitionId: "3",
            Name: "Machinery",
            Type: "StringType",
            StringValue: `${machineryData.unit} ${machineryData.model} / ${machineryData.serial_number}`
          }
        ]
      };

      // Add tax details if applicable
      if (selectedTaxRate > 0) {
        invoiceData.TxnTaxDetail = {
          TotalTax: calculateSubtotal() * selectedTaxRate,
          TaxLine: [{
            DetailType: "TaxLineDetail",
            Amount: calculateSubtotal() * selectedTaxRate,
            TaxLineDetail: {
              TaxRateRef: {
                value: "3" // This should be mapped to your QuickBooks tax rate ID
              },
              PercentBased: true,
              TaxPercent: selectedTaxRate * 100,
              NetAmountTaxable: calculateSubtotal()
            }
          }]
        };
      }

      // Log final request data
      console.log('Final invoice request:', {
        url: `/v3/company/${REALM_ID}/invoice`,
        baseURL: process.env.NODE_ENV === 'production' 
          ? 'https://quickbooks.api.intuit.com'
          : 'https://sandbox-quickbooks.api.intuit.com',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        data: invoiceData
      });

      // Log calculations
      console.log('Calculations:', {
        subtotal: calculateSubtotal(),
        taxRate: selectedTaxRate,
        taxAmount: calculateSubtotal() * selectedTaxRate,
        total: calculateSubtotal() * (1 + selectedTaxRate)
      });

      // Send to QuickBooks API v3
      const response = await api.post(`/v3/company/${REALM_ID}/invoice`, invoiceData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        baseURL: process.env.NODE_ENV === 'production' 
          ? 'https://quickbooks.api.intuit.com'
          : 'https://sandbox-quickbooks.api.intuit.com'
      });

      console.log('Invoice created successfully:', response.data);
      
      alert('Invoice created successfully!');
      onCreateInvoice();
    } catch (error) {
      console.error('Error creating invoice:', error.response?.data || error);
      alert('Error creating invoice. Please try again.');
    }
  };

  const handleDelete = () => {
    if (!selectedRow) {
      alert('Please select an item to delete');
      return;
    }
    
    const updatedItems = jobItems.filter(item => item.id !== selectedRow.id);
    setJobItems(updatedItems);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    if (!selectedRow) {
      alert('Please select an item to edit');
      return;
    }
    // TODO: Implement edit functionality
    // Could open a modal or form to edit the selected item
    console.log('Edit item:', selectedRow);
  };

  const handleCreate = () => {
    setIsAddingRow(true);
    setNewRow({
      type: '',
      name: '',
      description: '',
      quantity: '',
      rate: '',
    });
  };

  const handleNewRowChange = (field, value) => {
    setNewRow(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSaveNewRow = () => {
    // Validate fields
    if (!newRow.type || !newRow.name || !newRow.quantity || !newRow.rate) {
      alert('Please fill in all required fields');
      return;
    }

    const newItem = {
      id: Date.now(), // Temporary ID
      ...newRow,
      quantity: Number(newRow.quantity),
      rate: Number(newRow.rate)
    };

    setJobItems(prev => [...prev, newItem]);
    setIsAddingRow(false);
    setNewRow({
      type: '',
      name: '',
      description: '',
      quantity: '',
      rate: '',
    });
  };

  const handleCancelNewRow = () => {
    setIsAddingRow(false);
    setNewRow({
      type: '',
      name: '',
      description: '',
      quantity: '',
      rate: '',
    });
  };

  const handleRowClick = (item) => {
    setSelectedRow(selectedRow?.id === item.id ? null : item);
  };

  const calculateSubtotal = () => {
    return jobItems.reduce((sum, item) => sum + (item.quantity * item.rate), 0);
  };

  if (!isOpen || !job) return null;
  if (loading) return <div className="invoice-generation-popup">Loading...</div>;
  if (error) return <div className="invoice-generation-popup">Error: {error}</div>;

  // Create a formatted machinery string for the header
  const machineryString = machineryData ? 
    `${machineryData.unit} ${machineryData.model} | ${machineryData.serial_number}` : 
    'Loading...';

  // Create a formatted model/SN string for the form field
  const modelSnString = machineryData ? 
    `${machineryData.unit} ${machineryData.model} / ${machineryData.serial_number}` : 
    'Loading...';

  return (
    <div className="invoice-generation-popup">
      <div className="invoice-content">
        <div className="invoice-header">
          <h2>Invoice Generation - {job.type}</h2>

          {/* ----------------------------------------------  Seccion de Informacion  ------------------------------------------------------------------ */}
          <div className="header-info">

            <div className="client-info">
              <div className="info-item">
                <img src={icon_client} alt="Client" className="header-icon" />
                {clientData?.client_name || 'Loading...'}
              </div>
              <div className="info-item">
                <img src={icon_email} alt="Email" className="header-icon" />
                {clientData?.email || 'Loading...'}
              </div>
            </div>

            <div className="machinery-info">
              <div className="info-item">
                <img src={icon_machine} alt="Machinery" className="header-icon" />
                {machineryString}
              </div>
              <div className="info-item">
                <img src={icon_job} alt="Location" className="header-icon" />
                {job.type}
              </div>
            </div>
          </div>
        </div>

        <div className="invoice-body">
          {/* Left Column */}
          <div className="left-column">
            <div className="search-and-actions">
              <TextField
                fullWidth
                placeholder="Search..."
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton className="search-button">
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                className="search-input"
              />
              <div className="action-buttons">
                <IconButton 
                  className="delete-button" 
                  onClick={handleDelete}
                  disabled={!selectedRow}
                >
                  <img src={icon1} alt="Delete" />
                </IconButton>
                
                <IconButton 
                  className="edit-button" 
                  onClick={handleEdit}
                  disabled={!selectedRow}
                >
                  <img src={icon2} alt="Edit" />
                </IconButton>

                <IconButton 
                  className="add-button" 
                  onClick={handleCreate}
                >
                  <img src={icon3} alt="Add" />
                </IconButton>
              </div>
            </div>

            <div className="invoice-table">
              <div className="table-header">
                <div className="col">Type</div>
                <div className="col">Name</div>
                <div className="col">Description</div>
                <div className="col">Quantity</div>
                <div className="col">Hourly Rate/Each</div>
                <div className="col">Line Total</div>
              </div>
              <div className="table-body">
                {jobItems.map((item) => (
                  <div 
                    key={item.id} 
                    className={`table-row ${selectedRow?.id === item.id ? 'selected' : ''}`}
                    onClick={() => handleRowClick(item)}
                  >
                    <div className="col">{item.type}</div>
                    <div className="col">{item.name}</div>
                    <div className="col">{item.description}</div>
                    <div className="col">{item.quantity}</div>
                    <div className="col">${item.rate}</div>
                    <div className="col">${item.quantity * item.rate}</div>
                  </div>
                ))}
                {isAddingRow && (
                  <div className="table-row new-row">
                    <div className="col">
                      <input
                        type="text"
                        value={newRow.type}
                        onChange={(e) => handleNewRowChange('type', e.target.value)}
                        placeholder="Type"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        value={newRow.name}
                        onChange={(e) => handleNewRowChange('name', e.target.value)}
                        placeholder="Name"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        value={newRow.description}
                        onChange={(e) => handleNewRowChange('description', e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        value={newRow.quantity}
                        onChange={(e) => handleNewRowChange('quantity', e.target.value)}
                        placeholder="Quantity"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        value={newRow.rate}
                        onChange={(e) => handleNewRowChange('rate', e.target.value)}
                        placeholder="Rate"
                      />
                    </div>
                    <div className="col">
                      ${(Number(newRow.quantity || 0) * Number(newRow.rate || 0)).toFixed(2)}
                    </div>
                    <div className="row-actions">
                      <button onClick={handleSaveNewRow}>Save</button>
                      <button onClick={handleCancelNewRow}>Cancel</button>
                    </div>
                  </div>
                )}
                {!isAddingRow && jobItems.length === 0 && (
                  <div className="empty-state">No items assigned to this job</div>
                )}
              </div>
            </div>

            <div className="comments-section">
              <label>Comments</label>
              <textarea rows="4"></textarea>
            </div>
          </div>

          {/* Right Column */}
          <div className="right-column">
            <div className="invoice-details">
              <div className="form-group">
                <label>Invoice Number</label>
                <input type="text" />
              </div>
              <div className="form-group">
                <label>Bill to</label>
                <input 
                  type="text" 
                  value={clientData?.to_bill_location || 'Loading...'}
                  readOnly
                />
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Payment Terms</label>
                  <select>
                    <option value="">Select terms</option>
                  </select>
                </div>
                <div className="form-group right">
                  <label>Date</label>
                  <input 
                    type="date" 
                    value={job.work_date}
                    readOnly 
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Fleet Number</label>
                  <input type="text" />
                </div>
                <div className="form-group right">
                  <label>Hour Meter</label>
                  <input type="text" />
                </div>
              </div>
              <div className="form-group">
                <label>Type</label>
                <select>
                  <option value="">Select type</option>
                </select>
              </div>
              <div className="form-group">
                <label>Model / SN</label>
                <input 
                  type="text" 
                  value={modelSnString}
                  readOnly
                />
              </div>

              <div className="totals-section">
                <div className="tax-selection">
                  <label>Tax Rate</label>
                  <select 
                    value={selectedTaxRate} 
                    onChange={(e) => setSelectedTaxRate(parseFloat(e.target.value))}
                    className="tax-select"
                  >
                    {FLORIDA_TAX_OPTIONS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div className="total-row">
                  <label>Subtotal</label>
                  <span>${calculateSubtotal().toFixed(2)}</span>
                </div>
                <div className="total-row">
                  <label>Tax ({(selectedTaxRate * 100).toFixed(2)}%)</label>
                  <span>${(calculateSubtotal() * selectedTaxRate).toFixed(2)}</span>
                </div>
                <div className="total-row">
                  <label>Total</label>
                  <span>${(calculateSubtotal() * (1 + selectedTaxRate)).toFixed(2)}</span>
                </div>
                <div className="total-row">
                  <label>Payments Applied</label>
                  <span>$0.00</span>
                </div>
                <div className="total-row">
                  <label>Balance Due</label>
                  <span>${(calculateSubtotal() * (1 + selectedTaxRate)).toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="button-container">
          <button className="back-btn" onClick={onBack}>Back</button>
          <button 
            className="quickbooks-btn" 
            onClick={handleQuickBooksConnect}
          >
            Connect to QuickBooks
          </button>
          <button 
            className="create-invoice-btn" 
            onClick={handleCreateInvoice}
          >
            Create Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

InvoiceGenerationPopup.propTypes = {
  job: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default InvoiceGenerationPopup; 