import React, { useState, useEffect } from 'react';
import '../../styles/login_style/Login.scss';
import liftcoLogo from '../../assets/images/liftco_logo.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

import api from '../../utils/api';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      console.log('Submitting login form');
      const response = await api.post('/api/token/', { username, password });
      if (response.status === 200) {
        const data = response.data;
        console.log('Login successful');

        // Store tokens and decode user_id
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        const userId = jwtDecode(data.access).user_id;
        localStorage.setItem("userId", userId);

        // console.log("username", username)
        // console.log("password",  password)

        // console.log("access",  data.access)
        // console.log("userId",  userId)

        // Fetch user details
        const userResponse = await api.get(`/users/${userId}/`);
        const userData = userResponse.data;

        // Store user details
        localStorage.setItem('userName', userData.first_name + " " + userData.last_name);
        
        // Get group name by group id
        const groupResponse = await api.get('/groups/');
        const groups = groupResponse.data;
        const userGroup = groups.find(group => group.id === userData.groups[0]);
        localStorage.setItem('userRole', userGroup ? userGroup.name : '');
        localStorage.setItem('userGroupId', userGroup ? userGroup.id : '');

        // console.log("userRole",  userGroup.name)
        // console.log("userGroupId",  userGroup.id)

        const groupPermissionsResponse = await api.get(`/groups/${userGroup.id}/`);
        const permissions = groupPermissionsResponse.data.permissions;
        // console.log("permissions", permissions)

        const module_permissions = [];
        const codenamesInPermissions = permissions.map(permission => permission.codename)

        // console.log("codenamesInPermissions", codenamesInPermissions)

        const modules = [
          {
            name: "Clients Module",
            codenames: ["add_client", "change_client", "delete_client", "view_client"]
          },
          {
            name: "Inventory Module",
            codenames: ["add_item", "change_item", "delete_item", "view_item"]
          },
          {
            name: "Machinery Module",
            codenames: ["add_machinery", "change_machinery", "delete_machinery", "view_machinery"]
          },
          {
            name: "Provider Module",
            codenames: ["add_provider", "change_provider", "delete_provider", "view_provider"]
          },
          {
            name: "Purchase Order Module",
            codenames: ["add_purchaseorder", "change_purchaseorder", "delete_purchaseorder", "view_purchaseorder", 
                        "add_purchaseorderitem", "change_purchaseorderitem", "delete_purchaseorderitem", "view_purchaseorderitem"]
          },
          {
            name: "Job Module",
            codenames: ["add_status", "change_status", "delete_status", "view_status", 
                        "add_statuscategory", "change_statuscategory", "delete_statuscategory", "view_statuscategory", 
                        "add_activity", "change_activity", "delete_activity", "view_activity",
                        "add_itemactivity", "change_itemactivity", "delete_itemactivity", "view_itemactivity", 
                        "add_useractivity", "change_useractivity", "delete_useractivity", "view_useractivity",
                        "add_statuscategory", "change_statuscategory", "delete_statuscategory", "view_statuscategory"]
          },
          {
            name: "User Module",
            codenames: ["add_user", "change_user", "delete_user", "view_user"]
          },
        ];

        // console.log("Revisando permisos......")
        modules.forEach(module => {
          const hasPermissions = module.codenames.some(codename => codenamesInPermissions.includes(codename));
          // console.log("codename", module.name, hasPermissions)
          if (hasPermissions) {
            module_permissions.push(module.name);
          }
        });

        // Get unique content types and store them
        // const uniqueContentTypes = [...new Set(permissions.map(perm => perm.content_type))];
        // uniqueContentTypes.push(15);
        // localStorage.setItem('userPermissions', JSON.stringify(uniqueContentTypes));
        
        // Set fixed view to technician
        if(localStorage.getItem('userRole') == "Technician"){
          const updated_permissions = module_permissions.filter(
            permiso => !["User Module", "Clients Module", "Machinery Module", "Inventory Module"].includes(permiso)
          );
          
          localStorage.setItem('userPermissions', JSON.stringify(updated_permissions));
        } else if (localStorage.getItem('userRole') == "Parts Administrator"){
          const updated_permissions = module_permissions.filter(
            permiso => !["User Module", "Clients Module", "Machinery Module"].includes(permiso)
          );
          
          localStorage.setItem('userPermissions', JSON.stringify(updated_permissions));
        } else {
          localStorage.setItem('userPermissions', JSON.stringify(module_permissions));
        }
        //console.log("User permissions:", localStorage.getItem('userPermissions'));

        // Check for stored QuickBooks callback data
        const storedCallbackData = sessionStorage.getItem('qb_callback_data');
        console.log('Checking for stored callback data:', storedCallbackData);
        
        if (storedCallbackData) {
          console.log('Found QuickBooks callback data, redirecting back to callback');
          const callbackData = JSON.parse(storedCallbackData);
          window.location.href = callbackData.returnUrl;
        } else {
          console.log('Checking user role for redirect');
          // Check if user is a Technician and redirect accordingly
          if (userGroup.name === 'Technician') {
            console.log('User is a Technician, redirecting to Job Module');
            navigate('/job');
          } else {
            console.log('User is not a Technician, proceeding to home');
            navigate('/home');
          }
        }
        
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={liftcoLogo} alt="LIFTCO Logo" className="liftco-logo" />
        <form onSubmit={handleSubmit}>
          <div className="login-input-group">
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input 
                type="text" 
                id="username" 
                name="username" 
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input 
                type="password" 
                id="password" 
                name="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-button" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
          {isLoading && <div className="loader"></div>}
        </form>
        <Link to="/reset-account" className="forgot-password">Forgot my password</Link>
      </div>
    </div>
  );
}

export default Login;
