import React, { useState, useEffect } from 'react';
import '../styles/App.scss';
import Login from './login_mainview/Login';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link, useLocation} from 'react-router-dom';
import ResetAccount from './login_mainview/ResetAccount';
import Home from './home_view/Home';
import UserModule from './user_module_view/UserModule';
import MachineryModule from './machinery_module_view/MachineryModule';
import ProvidersModule from './providers_module_view/ProvidersModule';
import InventoryModule from './inventory_view/InventoryModule';
import ResetPasswordForm from './login_mainview/ResetPasswordForm';
import iconLogout from '../assets/images/icon_logout.svg';
import ClientsModule from './clients_module_view/ClientsModule';
import PurchaseOrderModule from './purchase_order_view/PurchaseOrderModule';
import JobModule from './job_module_view/JobModule';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TechnicianJobModule from './job_module_view/TechnicianJobModule';
import ProtectedRoute from '../components/ProtectedRoute';
import QuickBooksCallback from '../components/QuickBooksCallback';
import QuickBooksSuccess from './quickbooks/QuickBooksSuccess';
import ActiveJob from './active_job_view/ActiveJob';
import AssignItems from './assign_items_view/AssignItems';

function App() {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Router>
      <div className="App">
        <div className="main-content">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/reset-account" element={<ResetAccount />} />
              <Route path="/home" element={<Home />} />
              <Route path="/user" element={<UserModule />} />
              <Route path="/machinery" element={<MachineryModule />} />
              <Route path="/providers" element={<ProvidersModule />} />
              <Route path="/inventory" element={<InventoryModule />} />
              <Route path="/reset-password/:uid/:token" element={<ResetPasswordForm />} />
              <Route path="/clients" element={<ClientsModule />} />
              <Route path="/purchase-orders" element={<PurchaseOrderModule />} />
              <Route path="/job" element={<JobModule />} />
              <Route path="/jobs" element={<ProtectedRoute userType="technician"><TechnicianJobModule /></ProtectedRoute>} />
              <Route 
                path="/qa/oauth/intuit/callback/*" 
                element={<QuickBooksCallback />} 
              />
              <Route 
                path="/qa/oauth/intuit/call-:callbackId" 
                element={<QuickBooksCallback />} 
              />
              <Route path="/quickbooks/success" element={<QuickBooksSuccess />} />
              <Route path="/active-job" element={<ActiveJob />} />
              <Route path="/assign-items" element={<AssignItems />} />
            </Routes>
          </main>
          <footer className="App-footer">
            <h1>
              <span>LIFTCO - Management App</span>
              <span className="time-display">{currentTime}</span>
            </h1>
          </footer>
        </div>
      </div>
    </Router>
  );
}


function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRole');
    console.log("token eliminado");
    navigate('/');
  };

  const showLogout = location.pathname !== '/';

  const userName = localStorage.getItem('userName');
  const userRole = localStorage.getItem('userRole');

  return (
    <header className="App-header">
      <h1>LIFTCO - Management App</h1>
      {showLogout && (
        <div className="user-info">
          <AccountCircleIcon className="user-icon" />
          <span className="user-name">{userName}</span>
          <span className="user-div">·</span>
          <span className="user-role">{userRole}</span>
          <div className="icon-container" onClick={handleLogout}>
            <img src={iconLogout} alt="Logout" className="logout-icon" />
          </div>
        </div>
      )}
    </header>
  );
}


export default App;
