import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import './ActiveJob.scss';
import RequestPartsPopup from '../../components/RequestPartsPopup';
import AddNotePopup from '../../components/AddNotePopup';

const ActiveJob = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { job } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [showCloseWarning, setShowCloseWarning] = useState(false);
  const [startTime] = useState(new Date().toLocaleTimeString());
  const MAX_IMAGES = 10;
  const [showRequestParts, setShowRequestParts] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [assignedItems, setAssignedItems] = useState([]);
  const [locationDetails, setLocationDetails] = useState(null);
  const [machinery, setMachinery] = useState(null);

  const handleCancel = async () => {
    try {
      setIsLoading(true);
      
      // Get the status ID for "Job Assigned"
      const response = await api.get('/status/');
      const assignedStatus = response.data.find(status => status.name === "Job Assigned");
      
      if (!assignedStatus) {
        throw new Error("Could not find 'Job Assigned' status");
      }

      // Update job status back to "Job Assigned"
      await api.put(`/activities/${job.id}/`, {
        ...job,
        id_status: assignedStatus.id
      });

      // Clean up image previews
      images.forEach(image => URL.revokeObjectURL(image.preview));
      
      // Navigate back to job module
      navigate('/job');
    } catch (error) {
      console.error('Error cancelling job:', error);
      alert('Error cancelling job. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseJob = async () => {
    setShowCloseWarning(true);
  };

  const confirmCloseJob = async () => {
    try {
      setIsLoading(true);
      
      // Get the status ID for "Job Closed"
      const response = await api.get('/status/');
      const closedStatus = response.data.find(status => status.name === "Job Closed");
      
      if (!closedStatus) {
        throw new Error("Could not find 'Job Closed' status");
      }

      // Update job status to "Job Closed"
      await api.put(`/activities/${job.id}/`, {
        ...job,
        id_status: closedStatus.id
      });

      // Clean up image previews
      images.forEach(image => URL.revokeObjectURL(image.preview));
      
      // Navigate back to job module
      navigate('/job');
    } catch (error) {
      console.error('Error closing job:', error);
      alert('Error closing job. Please try again.');
    } finally {
      setIsLoading(false);
      setShowCloseWarning(false);
    }
  };

  const cancelCloseJob = () => {
    setShowCloseWarning(false);
  };

  const handleAddNote = () => {
    setShowAddNote(true);
  };

  const handleAddNoteClose = () => {
    setShowAddNote(false);
  };

  const handleAddNoteConfirm = async (note) => {
    try {
      setIsLoading(true);
      
      // Update job with new note
      await api.put(`/activities/${job.id}/`, {
        ...job,
        note: note
      });

      // Update local job state
      job.note = note;
      
      setShowAddNote(false);
    } catch (error) {
      console.error('Error adding note:', error);
      alert('Error adding note. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestParts = () => {
    setShowRequestParts(true);
  };

  const handleRequestPartsClose = () => {
    setShowRequestParts(false);
  };

  const handleRequestPartsConfirm = async (parts) => {
    try {
      setIsLoading(true);
      
      // Get the status ID for "Parts Ordered"
      const response = await api.get('/status/');
      const partsOrderedStatus = response.data.find(status => 
        status.name === "Parts Ordered"
      );
      
      if (!partsOrderedStatus) {
        throw new Error("Could not find 'Parts Ordered' status");
      }

      // Get the current user's activities
      const userResponse = await api.get('/auth/user/');
      const userId = userResponse.data.id;
      
      const userActivitiesResponse = await api.get(`/users/${userId}/activities/`);
      const currentActivityUser = userActivitiesResponse.data.find(
        ua => ua.assigned_flag === true && ua.id_activity === job.id
      );

      if (currentActivityUser) {
        // Update assigned_flag to false using the activity-user id
        await api.patch(`/activity-users/${currentActivityUser.id}/`, {
          assigned_flag: false
        });
      }

      // Update job status
      await api.put(`/activities/${job.id}/`, {
        ...job,
        id_status: partsOrderedStatus.id
      });

      // Create activity-items for each part
      const activityItemPromises = parts.map(part => 
        api.post('/activity-items/', {
          quantity_assigned: 0,
          quantity_requested: parseInt(part.quantity),
          id_activity: job.id,
          id_item: part.id
        })
      );

      await Promise.all(activityItemPromises);

      setShowRequestParts(false);
      setShowSuccessMessage(true);

      // Navigate after a delay to show the success message
      setTimeout(() => {
        navigate('/job');
      }, 2000);

    } catch (error) {
      console.error('Error requesting parts:', error);
      alert('Error requesting parts. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePause = async () => {
    try {
      setIsLoading(true);
      
      // Get the status ID for "Job Pause"
      const response = await api.get('/status/');
      const pauseStatus = response.data.find(status => status.name === "Job Pause");
      
      if (!pauseStatus) {
        throw new Error("Could not find 'Job Pause' status");
      }

      // Update job status to "Job Pause"
      await api.put(`/activities/${job.id}/`, {
        ...job,
        id_status: pauseStatus.id
      });

      // Clean up image previews
      images.forEach(image => URL.revokeObjectURL(image.preview));
      
      // Navigate back to job module
      navigate('/job');
    } catch (error) {
      console.error('Error pausing job:', error);
      alert('Error pausing job. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinish = async () => {
    try {
      setIsLoading(true);
      
      // Get the status ID for "MGMT Approval Needed"
      const response = await api.get('/status/');
      const closedStatus = response.data.find(status => status.name === "MGMT Approval Needed");
      
      if (!closedStatus) {
        throw new Error("Could not find 'MGMT Approval Needed' status");
      }

      // Get current user's ID
      const userId = localStorage.getItem('userid');

      // Get user's activities and find the current one
      const userActivitiesResponse = await api.get(`/users/${userId}/activities/`);
      const currentActivityUser = userActivitiesResponse.data.find(
        ua => ua.id_activity === job.id && ua.assigned_flag === true
      );

      if (currentActivityUser) {
        // Update time_finished for the activity-user
        await api.patch(`/activity-users/${currentActivityUser.id}/`, {
          time_finished: new Date().toISOString()
        });
      }

      // Update job status to "MGMT Approval Needed"
      await api.put(`/activities/${job.id}/`, {
        ...job,
        id_status: closedStatus.id
      });

      // Clean up image previews
      images.forEach(image => URL.revokeObjectURL(image.preview));
      
      // Navigate back to job module
      navigate('/job');
    } catch (error) {
      console.error('Error finishing job:', error);
      alert('Error finishing job. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    
    if (images.length + files.length > MAX_IMAGES) {
      alert(`You can only upload up to ${MAX_IMAGES} images`);
      return;
    }

    const newImages = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));

    setImages(prev => [...prev, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    setImages(prev => {
      const newImages = [...prev];
      URL.revokeObjectURL(newImages[index].preview); // Clean up URL object
      newImages.splice(index, 1);
      return newImages;
    });
  };

  // Clean up object URLs when component unmounts
  useEffect(() => {
    return () => {
      images.forEach(image => URL.revokeObjectURL(image.preview));
    };
  }, []);

  // Add useEffect to fetch assigned items
  useEffect(() => {
    const fetchAssignedItems = async () => {
      try {
        const [activityItemsResponse, itemsResponse] = await Promise.all([
          api.get(`/activities/${job.id}/items/`),
          api.get('/items/')
        ]);

        console.log('Activity Items Response:', activityItemsResponse.data);
        console.log('Items Response:', itemsResponse.data);

        // Create a map of item details for quick lookup
        const itemsMap = itemsResponse.data.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});
        console.log('itemsMap', itemsMap);

        // Combine activity items with their details
        const assignedItemsWithDetails = activityItemsResponse.data.map(activityItem => {
          console.log('Processing activity item:', activityItem);
          console.log('Looking up item:', itemsMap[activityItem.id_item]);
          return {
            ...activityItem,
            item_name: itemsMap[activityItem.id_item]?.item_name || 'Unknown Item',
            item_description: itemsMap[activityItem.id_item]?.description || ''
          };
        });

        setAssignedItems(assignedItemsWithDetails);
        console.log('Assigned items with details:', assignedItemsWithDetails);
      } catch (error) {
        console.error('Error fetching assigned items:', error);
      }
    };

    if (job?.id) {
      fetchAssignedItems();
    }
  }, [job?.id]);

  // Add useEffect to fetch machinery and location details
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        if (job?.id_machinery) {
          console.log('Fetching machinery details for job:', job.id_machinery);
          const machineryResponse = await api.get(`/machinery/${job.id_machinery}/`);
          console.log('Machinery response:', machineryResponse.data);
          setMachinery(machineryResponse.data);
        }
        
        if (job?.id_client) {
          console.log('Fetching client details for job:', job.id_client);
          const clientResponse = await api.get(`/clients/${job.id_client}/`);
          setLocationDetails(clientResponse.data);
        }
      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    };

    if (job) {
      fetchJobDetails();
    }
  }, [job]);

  return (
    <div className="active-job-container">
      <div className="content-wrapper">

        {/* ------------------------------------  Zona de titulo  ---------------------------------------- */}
        <div className="job-header">
          <div className="title-section">
            <h1>{job?.type}</h1>
            <div className="timer-badge">
              Start time: {startTime}
            </div>
          </div>
        </div>

        {/* ------------------------------------  Zona de Informacion  ---------------------------------------- */}
        <div className="info-card">
          <div className="info-item">
            <span className="info-label">Machinery</span>
            <p className="info-value">
              {machinery ? machinery.unit : 'Loading...'}
            </p>
          </div>
          <div className="info-item">
            <span className="info-label">Location</span>
            <p className="info-value">
              {locationDetails ? locationDetails.to_ship_location : 'Loading...'}
            </p>
          </div>
          <div className="info-item">
            <span className="info-label">Description</span>
            <p className="info-value">{job?.description || 'No description available'}</p>
          </div>
          <div className="info-item">
            <span className="info-label">Inventory</span>
            <div className="inventory-list-main">
              {assignedItems.length > 0 ? (
                assignedItems.map((item, index) => (
                  <div key={index} className="inventory-item">
                    <span className="item-name">{item.item_name}</span>
                    <span className="item-quantity">Qty: {item.quantity_assigned}</span>
                  </div>
                ))
              ) : (
                <p className="no-items">No items assigned to this job</p>
              )}
            </div>
          </div>
        </div>

        {/* ------------------------------------  Zona de Fotos  ---------------------------------------- */}
        <div className="media-section">
          <div className="upload-card">
            {images.length < MAX_IMAGES && (
              <div className="upload-area">
                <input 
                  type="file" 
                  accept="image/*" 
                  id="image-input" 
                  className="file-input"
                  capture="environment"
                  multiple
                  onChange={handleImageUpload}
                />
                <label htmlFor="image-input" className="upload-label">
                  <div className="upload-icon">📸</div>
                  <div className="upload-text-container">
                    <span className="upload-text">Click to upload or take a photo</span>
                    <span className="upload-hint">
                      {`${images.length}/${MAX_IMAGES} photos uploaded`}
                    </span>
                  </div>
                </label>
              </div>
            )}
            
            {images.length > 0 && (
              <div className="image-preview-grid">
                {images.map((image, index) => (
                  <div key={index} className="preview-item">
                    <img 
                      src={image.preview} 
                      alt={`Preview ${index + 1}`} 
                      className="preview-image"
                    />
                    <button 
                      className="remove-image-btn"
                      onClick={() => handleRemoveImage(index)}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* ------------------------------------  Zona de Botones  ---------------------------------------- */}
        <div className="action-buttons">
            <button 
              className="action-btn cancel" 
              onClick={handleCancel}
              disabled={isLoading}
            >
              {isLoading ? 'Cancelling...' : 'Cancel'}
            </button>
            <button 
              className="action-btn close" 
              onClick={handleCloseJob}
              disabled={isLoading}
            >
              {isLoading ? 'Closing...' : 'Close Job'}
            </button>
            <button 
              className="action-btn note" 
              onClick={handleAddNote}
              disabled={isLoading}
            >
              Add Note
            </button>
            <button className="action-btn parts" onClick={handleRequestParts}>
              Request Parts
            </button>
            <button 
              className="action-btn pause" 
              onClick={handlePause}
              disabled={isLoading}
            >
              {isLoading ? 'Pausing...' : 'Pause'}
            </button>
            <button 
              className="action-btn finish" 
              onClick={handleFinish}
              disabled={isLoading}
            >
              {isLoading ? 'Finishing...' : 'Finish'}
            </button>
          </div>
      </div>

      {showCloseWarning && (
        <div className="warning-modal">
          <div className="warning-content">
            <h2>Close Job</h2>
            <p>Are you sure you want to close this job? This action cannot be undone.</p>
            <div className="warning-buttons">
              <button 
                className="warning-btn confirm" 
                onClick={confirmCloseJob}
                disabled={isLoading}
              >
                {isLoading ? 'Closing...' : 'Yes, Close Job'}
              </button>
              <button 
                className="warning-btn cancel" 
                onClick={cancelCloseJob}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <RequestPartsPopup
        isOpen={showRequestParts}
        onClose={() => setShowRequestParts(false)}
        onConfirm={handleRequestPartsConfirm}
      />

      <AddNotePopup
        isOpen={showAddNote}
        onClose={handleAddNoteClose}
        onConfirm={handleAddNoteConfirm}
        currentNote={job?.note}
      />

      {showSuccessMessage && (
        <div className="success-message-overlay">
          <div className="success-message">
            <h2>✓ Success</h2>
            <p>Job successfully sent to parts administrator</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveJob; 